import { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";

import "../../constants/global.css";
import "./style/loginForm.css";
import { usersLogin, getAllUsersById } from "../../api/facom_services";
import { decodeJwtToken } from "../atoms/decode";
import Button from "../atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { setProfile, clearState } from "../../redux/actions/profile_actions";
import InputForm from "../atoms/InputForm";
import LoadingPag from "../atoms/LoadingPag";
import AppContext from "../../context/AppContext";
import useContents from "../../hooks/useContents";
import { ValidationAlert } from "../atoms";
import { LocalStorageKeys, getInLocalStorage, setInLocalStorage } from "../../helpers/local-storage-manager";
import { removeTags } from "../../helpers/removeHtml";
import InputPassword from "../atoms/InputPassword";

function LoginForm(props) {
  const { content, successValidationEmail } = useContext(AppContext);
  const { profile } = useSelector((state) => state.profileReducer);
  const { language } = profile;
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertWrong, setAlertWrong] = useState(false);
  const [alertWrongMessage, setAlertWrongMessage] = useState("");
  // const test = '<div style="color:red">Hola</div>';
  const [alertValidate, setAlertValidate] = useState(getInLocalStorage("validate"));
  const [alertUpdatePassword, setAlertUpdatePassword] = useState(getInLocalStorage("updatePassword"));
  const [alertUpdatePasswordMigrated, setAlertUpdatePasswordMigrated] = useState(getInLocalStorage("updatePasswordMigrated"));
  const [alertExpired, setAlertExpired] = useState(getInLocalStorage("expired"));

  const dispatch = useDispatch();
  const { contents } = useSelector((state) => state.profileReducer);
  const { getContents } = useContents(contents);
  const loginAccount = async () => {
    setLoading(!loading);
    try {
      if (!user || !password)
        return setAlertWrongMessage(language === "FR" ? "Please fill in the required fields" : "Veuillez remplir les champs obligatoires");
      const response = usersLogin(user, password);
      const data = (await response).data;
      if (!data.result?.migrated) {
        const userDec = decodeJwtToken(data?.result?.token, { complete: true });
        //CLEAR LOCAL STORAGE OF MIGRATED USER
        setInLocalStorage(LocalStorageKeys.USER_ID, "");
        setInLocalStorage(LocalStorageKeys.EMAIL, "");
        //CLEAR LOCAL STORAGE OF MIGRATED USER
        if (userDec) {
          dispatch(setProfile({ token: data?.result?.token }));
          dispatch(
            setProfile({
              email: userDec.payload.email,
              full_name: userDec.payload.first_name,
              sub: userDec.payload.sub,
              username: userDec.payload.username,
            })
          );
          //Languaje
          const response = getAllUsersById(userDec.payload.sub);
          const dataUser = (await response).data;
          const activeLanguage = `${dataUser?.result?.users_settings?.lenguage_active}`.toLowerCase();
          let languageACT = "FR";
          if (activeLanguage === "english") {
            languageACT = "EN";
          }
          if (activeLanguage === "french") {
            languageACT = "FR";
          }
          dispatch(setProfile({ language: languageACT }));
          getContents(languageACT, true);
        }
      } else {
        setInLocalStorage(LocalStorageKeys.USER_ID, data.result?.uuid);
        window.location.replace(`/verify-identity`);
      }
    } catch (error) {
      console.log("error login");
      console.log(error.response.data.message);
      if (language === "FR") {
        setAlertWrongMessage(
          `${error?.response?.data?.message}` === "Please verify your credentials"
            ? "Veuillez vérifier vos informations d'identification"
            : `${error?.response?.data?.message}` === "You need to verify your email before login, please check your email inbox"
            ? "Vous devez vérifier votre adresse e-mail avant de vous connecter, veuillez vérifier votre boîte de réception"
            : `${error?.response?.data?.message}` === "Incorrect username or password. Please try again."
            ? "Identifiant ou mot de passe incorrect. Veuillez réessayer."
            : `${error?.response?.data?.message}` === "The user is not authorized."
            ? "L'utilisateur n'est pas autorisé."
            : `${error?.response?.data?.message}` === "This user is disabled"
            ? "Votre accès est désactivé"
            : `${error?.response?.data?.message}`
        );
      } else {
        setAlertWrongMessage(`${error?.response?.data?.message}`);
      }
      setAlertWrong(true);
      dispatch(clearState());
    } finally {
      setLoading(false);
      console.log("end");
      setInLocalStorage("validate", false);
      setInLocalStorage("updatePassword", false);
      setInLocalStorage("expired", false);
      setAlertValidate(false);
      setAlertUpdatePassword(false);
      setAlertUpdatePasswordMigrated(false);
      setAlertExpired(false);
    }
  };

  const RenderError = () => {
    return <div className="my-3">{alertWrong && <ValidationAlert message={alertWrongMessage} variant="error" />}</div>;
  };

  const RenderSuccess = () => {
    return (
      <div className="my-3">
        {alertValidate && <ValidationAlert message={removeTags(`${content["ALERT-VALIDATE-ACCOUNT"]}`)} severity="success" />}
      </div>
    );
  };
  const RenderSuccessPassword = () => {
    return (
      <div className="my-3">
        {alertUpdatePassword && <ValidationAlert message={removeTags(`${content["ALERT-CHANGE-PASSWORD"]}`)} severity="success" />}
      </div>
    );
  };
  const RenderSuccessPasswordMigrated = () => {
    return (
      <div className="my-3">
        {alertUpdatePasswordMigrated && (
          <ValidationAlert message={removeTags(`${content["ALERT-CHANGE-PASSWORD-MIGRATED"]}`)} severity="success" />
        )}
      </div>
    );
  };

  const RenderErrorExpired = () => {
    return (
      <div className="my-3">
        {alertExpired && <ValidationAlert message={removeTags(`${content["ALERT-SESSION-EXPIRED"]}`)} severity="error" />}
      </div>
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    loginAccount();
  };
  const validate1 = getInLocalStorage("validate");
  const validate2 = getInLocalStorage("updatePassword");
  const validate3 = getInLocalStorage("expired");
  const validate4 = getInLocalStorage("updatePasswordMigrated");

  return (
    <div className="bg-white col-12">
      <h2 className="h2-stl Center ">
        <div dangerouslySetInnerHTML={{ __html: content["SING-IN-BOX.LOGIN-HOME.TITLE"] }} className="boxes-title spacing"></div>
      </h2>
      <div className="line-loggin"></div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <div dangerouslySetInnerHTML={{ __html: content["SING-IN-BOX.LOGIN-HOME.INPUT-EMAIL"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-8">
          <form action="/" onSubmit={handleSubmit}>
            <InputForm
              type="text"
              name="User"
              placeholder={removeTags(`${content["SING-IN-BOX.LOGIN-HOME.INPUT-EMAIL-PLACEHOLDER"]}`)}
              style={{ heigth: "250px" }}
              currentValue={user}
              handleOnInputValue={(data) => {
                setUser(data);
              }}
            />
          </form>
        </div>
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l">
            <div dangerouslySetInnerHTML={{ __html: content["SING-IN-BOX.LOGIN-HOME.INPUT-PASSWORD"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-8">
          <form action="/" onSubmit={handleSubmit} autoComplete="off">
            <InputPassword
              type="password"
              name="User"
              placeholder={removeTags(`${content["SING-IN-BOX.LOGIN-HOME.INPUT-PASSWORD"]}`)}
              style={{ heigth: "250px" }}
              currentValue={password}
              handleOnInputValue={(data) => {
                setPassword(data);
              }}
            />
            <RenderError />
            {validate1 === "true" ? <RenderSuccess /> : <></>}
            {validate2 === "true" ? <RenderSuccessPassword /> : <></>}
            {validate4 === "true" ? <RenderSuccessPasswordMigrated /> : <></>}
            {validate3 === "true" ? <RenderErrorExpired /> : <></>}
          </form>
          <div className="py-1"></div>
          <form action="/" onSubmit={handleSubmit}>
            <input type="submit" value="Connexion" style={{ display: "none" }}></input>
          </form>
          <Button message={removeTags(`${content["SING-IN-BOX.LOGIN-HOME.BUTTON"]}`)} onClick={() => loginAccount()} />
        </div>

        <div className="col-0 col-lg-2"></div>
      </div>
      <LoadingPag open={loading} />
    </div>
  );
}

export default LoginForm;
